
import './piroBox/piroBox.css'
export default {
  props: [],
  data() {
    return {
        show: false
    }
  },
  mounted() {   
    this.prioInit()
    if (!window.localStorage.getItem('is_init')) {
        window.localStorage.setItem('is_init', 1)
        $('#kuaisuuusu').click()
    }
  },
  methods: {
    kuaisu() {
        $('#kuaisuuusu').click()
    },
    test() {
        this.show = !this.show
    },
    prioInit() {
         $().piroBox({
			my_speed: 400, //animation speed
                bg_alpha: 0.3, //background opacity
                slideShow : true, // true == slideshow on, false == slideshow off
                slideSpeed : 4, //slideshow duration in seconds(3 to 6 Recommended)
                close_all : '.piro_close,.piro_overlay'// add class .piro_overlay(with comma)if you want overlay click close piroBox
        })
    },
    open() {
        window.open(`https://zgszcy.com/产信宝说明/shuomingshu.html`)
    }
  }
}
