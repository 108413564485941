import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken, getUserInfo, setUserInfo } from '@/utils/auth'

// import { resetRouter } from '@/router'
const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: '',
    showLoginDialog: false,
    vip: null
  }
}

const state = getDefaultState()

const mutations = {  
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
    // this.SHOW_LOGIN_DIALOG()
  },
  SET_TOKEN: (state, token) => {        
    state.token = token    
  },
  SET_USER_VIP: (state, vip) => {
    state.vip = vip 
  },
  SET_SNC_TOKEN: (state, token) => {        
    state.token = token    
    setToken(token)    
  },
  COMMIT_END_TIME: (state) => {
   
    
    
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },

  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SHOW_LOGIN_DIALOG: (state) => {
    state.showLoginDialog = true
  },
  HIDE_LOGIN_DIALOG: (state) => {
    state.showLoginDialog = false
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { sms_key, sms_code } = userInfo
    return new Promise((resolve, reject) => {
      login({ sms_key, sms_code }).then(response => {                
        if (response.code == 0) {
          const { data } = response
          commit('SET_TOKEN', data.token)                
          resolve(data)
        } else {
          reject(response.response)
        }
        
      }).catch(error => {        
        reject(error)
      })
    })
  },
  
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (getToken()) {
        getInfo().then(response => {        
          const { data: {user, vip} } = response
          if (!user) {
            return reject('Verification failed, please Login again.')
          }          
          const { nickname, avatar } = user        
          commit('SET_NAME', nickname)
          commit('SET_USER_INFO', user) 
          // vip.status = 'inactive'          
          commit('SET_USER_VIP', vip)
          // commit('SET_USER_VIP', false)        
          commit('SET_AVATAR', avatar || require('@/assets/2323.jpg'))
          // 如果是今天到期的话
          // 判断到期时间，超过到期时间后自动获取一次Info
          commit('COMMIT_END_TIME')
          resolve(user)
        }).catch(error => {
          reject(error)
        })
      }
      
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        removeToken() // must remove  token  first
        // resetRouter()
        commit('RESET_STATE')
        resolve()
      }).catch(error => {
        reject(error)
      })
        
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      commit('SHOW_LOGIN_DIALOG')
      
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

