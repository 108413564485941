import Vue from 'vue'
import VueCropper from 'vue-cropper'
import VueNotifications from 'vue-notifications'
import miniToastr from 'mini-toastr'
import Toasted from 'vue-toasted';
import Waves from 'vue-waves-effect';
import draggable from 'vuedraggable';
import './directive'
import SvgIcon from '../components/svgIcon'
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';
import LeeVue from 'leevueplugin'
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
}

// This step requires only for mini-toastr, just an initialization
miniToastr.init({types: toastTypes})
// Here we are seting up messages output to `mini-toastr`
// This mean that in case of 'success' message we will call miniToastr.success(message, title, timeout, cb)
// In case of 'error' we will call miniToastr.error(message, title, timeout, cb)
// and etc.
function toast ({title, message, type, timeout, cb}) {
  return miniToastr[type](message, title, timeout, cb)
}
// Here we map vue-notifications method to function abowe (to mini-toastr)
// By default vue-notifications can have 4 methods: 'success', 'error', 'info' and 'warn'
// But you can specify whatever methods you want.
// If you won't specify some method here, output would be sent to the browser's console
const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
}
Vue.use(VueNotifications, options)
Vue.use(Waves);
Vue.use(Toasted)
Vue.use( VueCropper )
Vue.component( SvgIcon.name, SvgIcon )
Vue.use(layer)
if ( process.client ) {
  Vue.use(LeeVue)
}
Vue.prototype.$layer = layer(Vue);
Vue.component('draggable', draggable);

// window.mapboxgl = mapboxgl
// Vue.component('LayLayer', LayLayer)

const req = require.context( '../assets/icons/svg/', false, /\.svg$/ )
const requireAll = requireContext => requireContext.keys().map( requireContext )
requireAll( req )


Vue.prototype.toasted = Toasted
if (process.client && process.env.NODE_ENV === "pro") { 
  window.addEventListener('devtoolschange', function (e) {
    debugger
  });
}

