
import Navbar from './components/Navbar'
import AppMain from './components/AppMain'
export default {
  components: {
    Navbar,
    AppMain
  },
  data() {
    return {
    }
  },
  watch : {
  },
  created() {
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation
      }
    }
  },
  methods: {
  },
  head() {
    return {
      link : [
        {
          rel : 'icon',
          type : 'image/x-icon',
          href : ''
        }
      ]
    }
  }
}
