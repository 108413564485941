
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  data() {
    return {
      item: {
        meta: {title: '讨论吧', sort: 6, isLink: true},
        name: "forum",
        path: "/forum"
      }
    }
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    routes() {     
      return this.$router.options.routes.sort( ( a , b ) => a.meta.sort - b.meta.sort)
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return true
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
