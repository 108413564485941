// import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getUserInfo() {
  if (process.client) {
    return window.localStorage.getItem('userInfo')
  }
 
}

export function setUserInfo(userInfo) {
  if (process.client) {
    return window.localStorage.setItem('userInfo', userInfo)
  }
 
}

export function getToken() {
  if (process.client) {
    return window.localStorage.getItem(TokenKey)
  }
  
}

export function setToken(token) {
  if (process.client) {    
    return window.localStorage.setItem(TokenKey, token)
  }
  
}

export function removeToken() {
  if (process.client) {
    return window.localStorage.removeItem(TokenKey)
  }
  
}
