
import { mapGetters  } from 'vuex'
import vipAlert from '@/components/vip-alert/vip-alert.vue'
export default {
  name: 'float-user-info',
  components: {
    vipAlert
  },
  computed: { 
    ...mapGetters(['userInfo', 'vip']),
    liveUrl() {
      return ''
    }
  },
  
  data() {
    return {
      
    }
  },
  mounted() {
  },
  methods: {
    toUserCenter() {
      
    },
    openVIPDialog() {
      this.$refs.vipAlertRef.showPop()
    },
    async logout() {
      await this.$store.dispatch('user/logout')  
    }
  },
}
