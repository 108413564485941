import { render, staticRenderFns } from "./base.vue?vue&type=template&id=43564cfb&scoped=true&"
import script from "./base.vue?vue&type=script&lang=js&"
export * from "./base.vue?vue&type=script&lang=js&"
import style0 from "./base.vue?vue&type=style&index=0&id=43564cfb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43564cfb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/Users/leo/Desktop/projects/pc/zgszcy/components/Footer/index.vue').default,BackTop: require('/Users/leo/Desktop/projects/pc/zgszcy/components/BackTop/index.vue').default})
