import { render, staticRenderFns } from "./float-user-info.vue?vue&type=template&id=272865da&scoped=true&"
import script from "./float-user-info.vue?vue&type=script&lang=js&"
export * from "./float-user-info.vue?vue&type=script&lang=js&"
import style0 from "./float-user-info.vue?vue&type=style&index=0&id=272865da&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272865da",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VipAlert: require('/Users/leo/Desktop/projects/pc/zgszcy/components/vip-alert/vip-alert.vue').default})
