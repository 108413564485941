// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuText": "#213547",
	"menuActiveText": "rgba(102,94,225,.8)",
	"subMenuActiveText": "rgba(102,94,225,.8)",
	"menuBg": "#fff",
	"menuHover": "#fff",
	"subMenuBg": "#fff",
	"subMenuHover": "#fff",
	"sideBarWidth": "auto"
};
module.exports = ___CSS_LOADER_EXPORT___;
