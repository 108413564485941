import Vue from 'vue';
import * as MapboxDrawWaypoint from 'mapbox-gl-draw-waypoint';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode
} from 'mapbox-gl-draw-circle';

Vue.prototype.$mapboxDrawWaypoint = MapboxDrawWaypoint;
Vue.prototype.$drawRectangle = DrawRectangle;
Vue.prototype.$circleMode = CircleMode;
Vue.prototype.$dragCircleMode = DragCircleMode;
Vue.prototype.$directMode = DirectMode;
Vue.prototype.$simpleSelectMode = SimpleSelectMode;