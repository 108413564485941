// import Cookies from 'js-cookie'

const state = {
  sidebar: {
    opened: true,
    withoutAnimation: false
  },
  isCloseRight: false,
  project_loading: false,
  cesuan_loading: false,
  project_id: '',
  device: 'desktop'
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      // Cookies.set('sidebarStatus', 1)
    } else {
      // Cookies.set('sidebarStatus', 0)
    }
  },  
  SET_PROJECT_ID: (state, project_id) => {
    state.project_id = project_id
  },
  doCloseRight: (state, isCloseRight) => {    
    state.isCloseRight = isCloseRight
  },
  SET_CESUAN_LOADING: (state, cesuan_loading) => {
    state.cesuan_loading = cesuan_loading
  },  
  SET_PROJECT_LOADING: (state, project_loading) => {
    state.project_loading = project_loading
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    // Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
