import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import getStore from '@/store'
import { getToken } from '@/utils/auth'
import Vue from 'vue'


const store = getStore();
// create an axios instance
const service = axios.create({
  baseURL: process.env.NODE_ENV === "dev" ? '/api':'https://cyy.pcmxj.com/api', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000000000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent              
    if (getToken()) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {    
    const res = response.data
    
    // if the custom code is not 20000, it is judged as an error.    
    if (res.code !== 0) {      
      Vue.toasted.error(res.msg || 'Error',{position: "top-center", duration: 5000})
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code == 401) {
        // to re-login
        MessageBox.confirm('登陆过期，请重新登陆', 'Confirm logout', {

          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {            
          })
        })
      }
      if (res.code == 400) {        
        Vue.toasted.error(error.response.data.msg || 'Error',{position: "top-center", duration: 5000})
      }

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {          
    console.log(error)         
    if (error.response.data.msg == '用户未扫码') {
      return Promise.reject(new Error(error.response.data.msg || 'Error'))
    }    
    else if (error.response.status == 401) {      
      store.dispatch('user/resetToken').then(() => {            
      })
      return new Promise((resolve, reject) => {
          reject(new Error('An error occurred'))
        }).catch((error) => {
          console.error(error) // 这将会在控制台打印错误，但不会抛出未处理的Promise拒绝错误
        })
    } 
    else if (error.response.status == 500) {
      Vue.toasted.error('系统异常',{position: "top-center", duration: 5000})
    }
    else if (error.response.data.msg == '订单不存在') {
      return  new Promise((resolve, reject) => {
        reject(new Error(error.response.data.msg))
      }).catch((error) => {
        console.error(error) // 这将会在控制台打印错误，但不会抛出未处理的Promise拒绝错误
      }) //  Promise.reject(new Error(error.response.data.msg || 'Error'))
    } else if (error.response.status == 400) {
      Vue.toasted.error(error.response.data.msg || 'Error',{position: "top-center", duration: 5000})
    }    
    return error
  }
)

export default service
