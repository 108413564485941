
const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  isLogin: state => state.user.token ? true : false,
  showLoginDialog: state => state.user.showLoginDialog,
  userInfo: state => state.user.userInfo,
  avatar: state => state.user.avatar,
  vip: state => state.user.vip,
  project_id: state => state.app.project_id,
  name: state => state.user.name
}
export default getters
