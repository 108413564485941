
import { mapGetters, mapState } from 'vuex'
import Sidebar from './Sidebar/index'
import Login from '@/components/login'
import FloatUserInfo from './float-user-info/float-user-info'
import { getToken } from '@/utils/auth'

import config from '@/config'
const dayjs = require('dayjs');
export default {
  components: {    
    Sidebar,
    Login,
    FloatUserInfo    
  },
  computed: {
    ...mapGetters([
      'avatar',
      'isLogin',
      'name',
      'showLoginDialog'
    ])    
  },
  watch: {
    showLoginDialog (val) {
      if (val) {
        this.showLogin()
      }
    },
    isLogin: {
      async handler(val, oldName) {        
        if (val) {                
          await this.$store.dispatch('user/getInfo')
          // 如果是今天到期的话
          // 判断到期时间，超过到期时间后自动获取一次Info
          // 判断是否今天到期
          if (this.$store.state.user.vip) {
            const isToday = dayjs(this.$store.state.user.vip.end_date).isSame(dayjs(), 'day')
            if (isToday) {
                // 将字符串转换为 Day.js 对象，并加上10分钟
                let targetTime = dayjs(this.$store.state.user.vip.end_date).add(10, 'minute');
                // 计算现在到目标时间的毫秒数，以设置定时器
                let delay = targetTime.diff(dayjs());
                setTimeout( () => {    
                  this.$store.dispatch('user/getInfo')
                }, delay);
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      showInfo: false,
      STATIC_URL: config.STATIC_URL,
      routers: [
        {},
        {},
        {}
      ],
      activeIndex: 0
    }
  },
  mounted() {    
    if (getToken()) {
      this.$store.commit('user/SET_TOKEN',getToken())
    }
    this.checkLogin()
  },
  methods: {  
    getLink4() {
      return window.open(`https://edu.pcmxj.com?token=${getToken()}&r=1701067101614`)
    },
    getLink3() {
      return window.open(`https://chatgpt.pcmxj.com/gpt-chat-h5/#/pages/main/chat/index?token=${getToken()}&r=1701067083756`)
    },
    getLink2() {
      return window.open(this.$router.resolve({
        　　path: `/bonusPoints`
        }).href)
    },
    getLink1() {
      return window.open(`https://bbs.zgszcy.com/${getToken()}`)
    },
    // 检查头部
    async checkLogin() {
      const { isLogin } = this.$route.query
      if ( isLogin ) {
        await this.logout()
        this.showLogin()
      }
    },
    showLogin() {
        this.$refs.loginComp.showPop()
    },
    async logout() {
      await this.$store.dispatch('user/logout')      
    }
  }
}
